import React from "react"

import Cta from '../lib/components/Cta'
import ImageGrid from '../lib/components/ImageGrid'
import Layout from "../lib/components/Layout"
import NexusButton from "../lib/components/NexusButton"
import PageTitlePanel from '../lib/components/PageTitlePanel'
import Storage from '../lib/storage'

function Page({ location }) {

  // React hook for checking if screen is mobile size
  const [width, setWidth] = React.useState(null);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      }
    }
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth)
    }

  }, []);
  const isMobile = width <= 768;

  return (
    <Layout
      title={Storage.pages.services.metadata.title}
      description={Storage.pages.services.metadata.description}
      pageUrl={location.pathname}
      bgNavSelector="titlePanel"
      fixedButton="backToHome"
    >
      <PageTitlePanel
        title={Storage.pages.services.metadata.title}
        subtitle={Storage.pages.services.metadata.description}
        backgroundImg={Storage.pages.services.titlePanel.backgroundImg}
        isMobile={isMobile}
        relativeUrl={location.pathname}
      />

      <ImageGrid
        title='Our Waterproofing Services'
        subtitle={(
          <span className="grid gap-4">
            <span className="nexus-text">{Storage.pages.services.metadata.description}</span>
            <span className="nexus-text text-sm font-bold">{isMobile ? 'Swipe on your phone or click the buttons on your desktop' : 'Click on each panel'} to look through our available services.</span>
          </span>
        )}
        imageObjects={Storage.global.services}
      />

      <Cta
        backgroundImg={Storage.pages.services.cta.img}
        title={
          <div className="grid gap-4">
            <span className="block">Are you interested in a quote for one of our waterproofing services?</span>
            <span className="block text-base font-normal">We offer free quote repairs from $80.</span>
          </div>
        }
        btn={<NexusButton title={`Enquire Now`} link='https://shop.nexuscommercial.com.au' linkType='external' theme='dark' />}
        type='footer'
      />

    </Layout>
  )
}

export default Page
