import { motion, useAnimation, useReducedMotion } from "framer-motion"
import React from "react"
import { useInView } from "react-intersection-observer"
import { Breadcrumb } from "./Breadcrumb"

const fadeUp = {
  initial: { y: '20px', opacity: 0 },
  animate: { y: 0, opacity: 1, transition: { duration: 0.5 } },
  reducedMotion: { opacity: 1 }
}

function Cta({ backgroundImg, title, id, btn, type, relativeUrl, isMobile }) {

  const prefersReducedMotion = useReducedMotion()
  const control = useAnimation()
  const [ref, inView] = useInView()

  React.useEffect(() => {
    if (isMobile || prefersReducedMotion) control.start("reducedMotion")
    else if (inView) control.start("animate");
    else control.start("initial");
  }, [control, inView, isMobile, prefersReducedMotion]);

  return (
    <motion.div
      id={id ? id : null}
      className="relative block w-full h-96 overflow-hidden"
      ref={ref}
    >
      {backgroundImg}
      {/* BG Overlay */}
      {
        type === 'header' ? <div className="absolute inset-0 w-full h-full nexus-neutral-bg bg-opacity-70 mix-blend-multiply"></div> :
          type === 'footer' ? <div className="absolute top-0 left-0 w-full h-full bg-neutral-800 mix-blend-multiply bg-opacity-80 transition-all duration-500 ease-in"></div>
            : null
      }
      {/* Text Container */}
      <div className="absolute inset-0 object-center">
        <motion.div
          className="relative w-full h-full text-center uppercase flex flex-col justify-center items-center gap-6"
          variants={fadeUp}
          initial="initial"
          animate={control}
        >
          <h1 className={`px-4 py-4 lg:px-7 lg:py-5 w-auto nexus-neutral-bg bg-opacity-50 mix-blend-screen backdrop-blur-2xl nexus-light-text leading-relaxed tracking-wider !font-black uppercase ${type === 'header' ? 'text-2xl lg:text-4xl ' : type === 'footer' ? 'text-2xl' : 'text-base'}`}>{title}</h1>
          {btn}
        </motion.div>
      </div>

      {
        type === 'header' && <Breadcrumb relativeUrl={relativeUrl} />
      }
    </motion.div>
  )
}

export default Cta